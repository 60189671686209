.parallax-card {
    display: flex;
    //align-items: center;
    justify-content: center;
    height: 100%;
}


.purple {
    background-color: #8253DF;
}

.pink {
    background-color: #FF47A6;
}

.invisible {
    opacity: 0;
}

.fiblo {
    //  margin-top: 28vh;
}

/* .finablomberg {
    position: relative;
}
 */
.description {
    position: relative;
    margin-top: 25vh;
}

.contact {
    position: relative;
    top: 4rem;
    margin-top: 35vh;
}


.introduction {
    font-size: 4rem;
    color: $raisin-black;
    color: $lavender-floral;
    color: $lavender-floral2;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

$secondary-color: #FF70BA;
$main-color: #FF47A6;

.pixelname {
    font-size: 7rem;
    font-family: 'Silkscreen';
    color: white;
    text-transform: uppercase;
    text-shadow: 3px 1px 1px $secondary-color,
        2px 2px 1px $main-color,
        4px 2px 1px $secondary-color,
        3px 3px 1px $main-color,
        5px 3px 1px $secondary-color,
        4px 4px 1px $main-color,
        6px 4px 1px $secondary-color,
        5px 5px 1px $main-color,
        7px 5px 1px $secondary-color,
        6px 6px 1px $main-color,
        8px 6px 1px $secondary-color,
        7px 7px 1px $main-color,
        9px 7px 1px $secondary-color;

}
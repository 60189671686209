/* SCSS HEX */
$raisin-black: #272838ff;
$lavender-floral: #b498ecff;
$amaranth-pink: #f094b2ff;
$peach: #ffbe86ff;

.body {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
}

.full__page {
    width: 100vw;
    height: 100vh;
}

.svgContainer {
    display: flex;
    align-items: flex-end;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.row-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.column-reverse {
    display: flex;
    flex-direction: column-reverse;
}

.gap-1 {
    gap: 1rem;
}

.gap-2 {
    gap: 2rem;
}

.justify-start {
    justify-content: start;
}

.justify-end {
    justify-content: end;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}



.items-start {
    align-items: start;
}

.items-end {
    align-items: end;
}

.items-center {
    align-items: center;
}

.items-baseline {
    align-items: baseline;
}

.items-stretch {
    align-items: stretch;
}

.content-start {
    align-content: start;
}

.content-end {
    align-content: end;
}

.content-center {
    align-content: center;
}

.content-between {
    align-content: space-between;
}

.content-around {
    align-content: space-around;
}

.content-stretch {
    align-content: stretch;
}


.m-b-1 {
    margin-bottom: 1rem;
}
.m-b-2 {
    margin-bottom: 2rem;
}